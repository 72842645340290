





















import { showError } from '@/utils/common'
import { defineComponent, ref } from '@vue/composition-api'
import { getShareInfoApi } from '@/api/observer'
import TitleTooltip from '@/components/TitleTooltip.vue'

export default defineComponent({
    components: {
        TitleTooltip
    },
    setup(props, { root, refs }) {
        const shareUuid = root.$route.params.shareUuid
        const title = ref('')
        const getShareData = async () => {
            try {
                const data = await getShareInfoApi({
                    uuid: shareUuid
                })
                title.value = data.project.detail_title
            } catch (error) {
                showError(error)
            }
        }
        getShareData()
        return {
            title
        }
    }
})
